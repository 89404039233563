import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import ScrollSection from "../components/scroll-section/scroll-section";
import ArticleList from "../components/article-list/article-list";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import { URLS } from "../config";

class ContientPageTemplate extends React.Component {
  render() {
    const posts = get(this.props, "data.allContentfulPost.edges");
    const category = get(this.props, "data.contentfulContinent");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const { displayTitle, image, preTitle, subtitle, slug, title } = category;

    let countries = [];
    posts.forEach((post) => countries.push(post.node.country));
    countries = countries.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.slug === value.slug)
    );

    countries = countries.sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: `${title} Travel`,
            seoDescription: `${title} Travel`,
          }}
        />

        <ScrollSection>
          <Hero
            image={image}
            pretitle={{
              title: preTitle.toUpperCase(),
            }}
            links={countries}
            subtitle={subtitle}
            title={displayTitle.toUpperCase() || title.toUpperCase()}
            svg={slug}
            type="default"
          />
        </ScrollSection>

        <ScrollSection id="wrapper">
          <ArticleList type="continent" posts={posts} />
          <Breadcrumb
            className="mt-20 mb-2"
            items={[
              {
                key: "id_1",
                link: URLS.Archive,
                title: "Travel Stories",
              },
            ]}
            current={title}
          />
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContientPageTemplate;

export const pageQuery = graphql`
  query CategoryQuery($id: String!) {
    contentfulContinent(id: { eq: $id }) {
      displayTitle
      id
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
      preTitle
      slug
      subtitle
      seoDescription
      title
    }
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { country: { continent: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate
          minuteRead
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            displayTitle
            firstname
            surname
            location
            slug
          }
          country {
            slug
            title
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 424
                height: 212
              )
            }
            continent {
              title
              slug
            }
          }
        }
      }
    }
  }
`;
